import './navbar.css'
import React, { useState } from 'react';
export default function MyComponent({ onNavItemClick, userType }) {
    const [selectedNavItem, setSelectedNavItem] = useState('messages'); // Default selected item

    const handleItemClick = (itemName) => {
        setSelectedNavItem(itemName);
        onNavItemClick(itemName);
    };
    return (
        <>
            <div className="div">
                <div className="div-2">
                    {userType === 'moderator' && (
                        <div className="div-3">
                            { }
                            <div className={`div-66 ${selectedNavItem === 'Body-Analysis' ? 'selected' : ''}`} onClick={() => handleItemClick('Body-Analysis')}>
                                <img
                                    loading="lazy"
                                    src="paper.svg"
                                    className="img-2"
                                />
                                <div className="div-7">Body-Analysis</div>
                            </div>
                            <div className={`div-6 ${selectedNavItem === 'The gym' ? 'selected' : ''}`} onClick={() => handleItemClick('The gym')}>
                                <img
                                    loading="lazy"
                                    src="paper.svg"
                                    className="img-2"
                                />
                                <div className="div-7">The Gym Program</div>
                            </div>
                            <div className={`div-6 ${selectedNavItem === 'ramadan' ? 'selected' : ''}`} onClick={() => handleItemClick('ramadan')}>
                                <img
                                    loading="lazy"
                                    src="paper.svg"
                                    className="img-2"
                                />
                                <div className="div-7">Ramadan Program </div>
                            </div>
                            <div className={`div-6 ${selectedNavItem === 'Shred-30' ? 'selected' : ''}`} onClick={() => handleItemClick('Shred-30')}>
                                <img
                                    loading="lazy"
                                    src="paper.svg"
                                    className="img-2"
                                />
                                <div className="div-7">Shred-30 Program </div>
                            </div>
                            <div className={`div-6 ${selectedNavItem === 'Circuit' ? 'selected' : ''}`} onClick={() => handleItemClick('Circuit')}>
                                <img
                                    loading="lazy"
                                    src="paper.svg"
                                    className="img-2"
                                />
                                <div className="div-7">Circuit Program</div>
                            </div>
                            <div className={`div-6 ${selectedNavItem === 'Gwlp' ? 'selected' : ''}`} onClick={() => handleItemClick('Gwlp')}>
                                <img
                                    loading="lazy"
                                    src="paper.svg"
                                    className="img-2"
                                />
                                <div className="div-7" >GWLP 30 Program</div>
                            </div>

                            <div className={`div-6 ${selectedNavItem === 'Concept Her' ? 'selected' : ''}`} onClick={() => handleItemClick('Concept Her')}>
                                < img
                                    loading="lazy"
                                    src="paper.svg"
                                    className="img-2"
                                />
                                <div className="div-7">Concept Her Program</div>
                            </div>

                            <div className={`div-6 ${selectedNavItem === 'Revival' ? 'selected' : ''}`} onClick={() => handleItemClick('Revival')}>
                                <img
                                    loading="lazy"
                                    src="paper.svg"
                                    className="img-2"
                                />
                                <div className="div-7">Revival Program</div>
                            </div>


                            <div className={`div-6 ${selectedNavItem === 'Pregnant' ? 'selected' : ''}`} onClick={() => handleItemClick('Pregnant')}>
                                <img
                                    loading="lazy"
                                    src="paper.svg"
                                    className="img-2"
                                />
                                <div className="div-7">Pregnant Program</div>
                            </div>

                            <div className={`div-6 ${selectedNavItem === 'Youssef' ? 'selected' : ''}`} onClick={() => handleItemClick('Youssef')}>
                                <img
                                    loading="lazy"
                                    src="paper.svg"
                                    className="img-2"
                                />
                                <div className="div-7">Youssef Program</div>
                            </div>


                            <div className={`div-6 ${selectedNavItem === 'Climbing' ? 'selected' : ''}`} onClick={() => handleItemClick('Climbing')}>
                                <img
                                    loading="lazy"
                                    src="paper.svg"
                                    className="img-2"
                                />
                                <div className="div-7">Climbing Program</div>
                            </div>

                            <div className={`div-6 ${selectedNavItem === 'user' ? 'selected' : ''}`} onClick={() => handleItemClick('user')}>
                                <img
                                    loading="lazy"
                                    src="person.svg"
                                    className="img-2"
                                />
                                <div className="div-7">User Management</div>
                            </div>

                            <div className={`div-6 ${selectedNavItem === 'contact' ? 'selected' : ''}`} onClick={() => handleItemClick('contact')}>
                                <img
                                    loading="lazy"
                                    src="message.svg"
                                />
                                <div className="div-7">Contact Us Messages</div>
                            </div>

                        </div>
                    )}

                    {userType === 'user' && (
                        <div className="div-3">
                            <div className={`div-6 ${selectedNavItem === 'contact' ? 'selected' : ''}`} onClick={() => handleItemClick('contact')}>
                                <img
                                    loading="lazy"
                                    src="message.svg"
                                />
                                <div className="div-7">Contact Us Messages</div>
                            </div>
                        </div>
                    )}
                </div>
            </div >
        </>
    );
}


